import { render } from "./Spo2Chart.vue?vue&type=template&id=9952d8e4&scoped=true"
import script from "./Spo2Chart.vue?vue&type=script&setup=true&lang=js"
export * from "./Spo2Chart.vue?vue&type=script&setup=true&lang=js"

import "./Spo2Chart.vue?vue&type=style&index=0&id=9952d8e4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-9952d8e4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "9952d8e4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9952d8e4', script)) {
    api.reload('9952d8e4', script)
  }
  
  module.hot.accept("./Spo2Chart.vue?vue&type=template&id=9952d8e4&scoped=true", () => {
    api.rerender('9952d8e4', render)
  })

}

script.__file = "src/views/measurements/casePeriod/Spo2Chart.vue"

export default script