<template>
  <div class="threshold-setting-container">
    <div class="unitTitle">{{ t('MS001.unit') }}</div>
    <div class="unit">
      <a-select size="large" v-model:value="selectUnit" style="width: 318px">
        <a-select-option value="kg">{{ t('MS004.kg') }}</a-select-option>
        <a-select-option value="lbs">{{ t('MS004.lbs') }}</a-select-option>
      </a-select>
    </div>

    <!-- <a-button class="refactory-btn" @click="confirmRefactory">
      {{ t('MS001.defaultSetting') }}
    </a-button> -->
    <a-button class="confirm-btn" @click="confirmModifyUnit">
      {{ t('MS001.confirmSetting') }}
    </a-button>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { message } from 'ant-design-vue'
  export default defineComponent({
    setup() {
      let selectUnit = ref('')
      const store = useStore()
      const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
      const patchMeasureThreshold = (postData) =>
        store.dispatch('measureThreshold/patchMeasureThreshold', postData)
      const putMeasureThreshold = (postData) =>
        store.dispatch('measureThreshold/putMeasureThreshold', postData)
      const measureThreshold = computed(
        () => store.state.measureThreshold.measureThreshold
      )
      let measureThresholdDisplay = ref({})
      onMounted(() => {
        if (measureThreshold?.value?.bodyWeight?.length > 0) {
          // 公制
          selectUnit.value = measureThreshold?.value?.bodyWeight[0].unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bodyWeight[0],
          }
        } else {
          selectUnit.value = measureThreshold?.value?.bodyWeight.unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bodyWeight,
          }
        }
      })
      watch(measureThreshold, () => {
        if (measureThreshold?.value?.bodyWeight?.length > 0) {
          // 公制
          selectUnit.value = measureThreshold?.value?.bodyWeight[0].unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bodyWeight[0],
          }
        } else {
          selectUnit.value = measureThreshold?.value?.bodyWeight.unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bodyWeight,
          }
        }
      })

      const confirmModifyUnit = async () => {
        const postBody = {
          bloodPressure: {},
          bloodGlucose: {},
          temperature: {},
          bodyWeight: { unit: selectUnit.value },
          stature: {},
        }
        if (measureThreshold?.value?.bloodGlucose?.length > 0) {
          postBody.bloodPressure = {
            ...measureThreshold?.value?.bloodPressure[0],
          }
          postBody.bloodGlucose = {
            ...measureThreshold?.value?.bloodGlucose[0],
          }
          postBody.temperature = {
            ...measureThreshold?.value?.temperature[0],
          }
          // postBody.bodyWeight = {
          //   ...measureThreshold?.value?.bodyWeight[0],
          // }
          postBody.stature = {
            ...measureThreshold?.value?.stature[0],
          }
        } else {
          postBody.bloodPressure = {
            ...measureThreshold?.value?.bloodPressure,
          }
          postBody.bloodGlucose = {
            ...measureThreshold?.value?.bloodGlucose[0],
          }
          postBody.temperature = {
            ...measureThreshold?.value?.temperature[0],
          }
          // postBody.bodyWeight = {
          //   ...measureThreshold?.value?.bodyWeight,
          // }
          postBody.stature = {
            ...measureThreshold?.value?.stature,
          }
        }

        await putMeasureThreshold(postBody)
        message.success(t('MS004.confirmSettingComplete'))
      }

      // const confirmRefactory = async () => {
      //   const postBody = {
      //     bodyWeightUnit: 'kg',
      //   }
      //   await patchMeasureThreshold(postBody)
      // }

      return {
        t,
        measureThreshold,
        patchMeasureThreshold,
        putMeasureThreshold,
        selectUnit,
        measureThresholdDisplay,
        confirmModifyUnit,
        // confirmRefactory,
      }
    },
  })
</script>

<style lang="less" scoped>
  .titleFont() {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #7b7b7b;
  }
  .symbolFont() {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    color: #000000;
  }
  .threshold-setting-container {
    position: relative;
    .unitTitle {
      position: absolute;
      left: 110px;
      top: 62px;
      width: 80px;
      height: 25px;
      .titleFont();
    }
    .unit {
      position: absolute;
      left: 110px;
      top: 95px;
      width: 318px;
      height: 55px;
    }

    .refactory-btn {
      position: absolute;
      left: 300px;
      top: 455px;
      width: 135px;
      height: 40px;
      background: #bdbdbd;
      border-radius: 4px;
    }
    .confirm-btn {
      position: absolute;
      left: 383px;
      top: 455px;
      width: 135px;
      height: 40px;
      background: #4ebaa8;
      border-radius: 4px;
    }
  }
</style>
