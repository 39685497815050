const measuredVisits = {
  ok: true,
  amount: 16,
  data: [
    {
      type: 'member',
      amount: 10,
      percentage: 63,
      groups: [
        {
          name: '鋼鐵人隊',
          amount: 7,
          percentage: 70,
        },
        {
          name: 'widespread',
          amount: 2,
          percentage: 20,
        },
        {
          name: '美國隊長隊',
          amount: 1,
          percentage: 10,
        },
      ],
    },
    {
      type: 'hasIDs',
      amount: 2,
      percentage: 13,
    },
    {
      type: 'guest',
      amount: 4,
      percentage: 25,
    },
  ],
}

const measuredClassifiedAbnormal = {
  ok: true,
  data: [
    {
      type: 'BPMeasurement',
      amount: 150,
      abnormal: 25,
    },
    {
      type: 'GlucoseMeasurement',
      amount: 25,
      abnormal: 12,
    },
    {
      type: 'OxygenSatMeasurement',
      amount: 100,
      abnormal: 5,
    },
    {
      type: 'BodyTempMeasurement',
      amount: 150,
      abnormal: 50,
    },
    {
      type: 'BMI',
      amount: 75,
      abnormal: 50,
    },
  ],
}
const measuredClassifiedAbnormal_hasIds = {
  ok: true,
  data: [
    {
      type: 'BPMeasurement',
      amount: 0,
      abnormal: 0,
    },
    {
      type: 'GlucoseMeasurement',
      amount: 0,
      abnormal: 0,
    },
    {
      type: 'OxygenSatMeasurement',
      amount: 1,
      abnormal: 0,
    },
    {
      type: 'BodyTempMeasurement',
      amount: 2,
      abnormal: 1,
    },
    {
      type: 'BMI',
      amount: 0,
      abnormal: 0,
    },
  ],
}

module.exports = [
  {
    url: '/statistics.measuredVisits',
    type: 'get',
    response() {
      return measuredVisits
    },
  },
  {
    url: '/statistics.measuredClassifiedAbnormal',
    type: 'get',
    response(config) {
      if (
        config &&
        config.query &&
        config.query.subjectType &&
        config.query.subjectType === 'all'
      ) {
        return measuredClassifiedAbnormal
      } else {
        return measuredClassifiedAbnormal_hasIds
      }
    },
  },
]
