<template>
  <VChart class="chart" :option="option" :update-options="{ notMerge: true }" />
</template>

<script setup>
  import { use } from 'echarts/core'
  import { CanvasRenderer } from 'echarts/renderers'
  import { CandlestickChart } from 'echarts/charts'
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  import { ref, defineProps, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  use([
    CanvasRenderer,
    CandlestickChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
  ])

  const { t } = useI18n()

  const props = defineProps({
    startTs: {
      type: Number,
      default: 0,
    },
    endTs: {
      type: Number,
      default: 0,
    },
  })

  // provide(THEME_KEY, 'dark')
  // var emphasisStyle = {
  //   itemStyle: {
  //     shadowBlur: 10,
  //     shadowColor: 'rgba(0,0,0,0.3)',
  //   },
  // }

  const option = ref({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
        type: 'cross',
        lineStyle: {
          color: '#376df4',
          width: 2,
          opacity: 1,
        },
      },
      formatter: function (param) {
        if (param[0].seriesType == 'candlestick') {
          console.warn(param)
          return [
            '日期' + ': ' + param[0].name + '<hr size=1 style="margin: 3px 0">',
            '開盤價' + ': ' + param[0].data[1] + ' <br/> ',
            '最高價' + ': ' + param[0].data[4] + '<br/>',
            '最低價' + ': ' + param[0].data[3] + '<br/>',
            '收盤價' + ': ' + param[0].data[2] + '<br/>',
            '成交量' + ': ' + param[1].data + '<br/>',
          ].join('')
        }
        // } else if (param[0].seriesType == 'bar') {
        //   return [
        //     '日期' + ': ' + param[1].name + '<hr size=1 style="margin: 3px 0">',
        //     '開盤價' + ': ' + self.CommaFormat(param[1].data[1]) + '<br/>',
        //     '最高價' + ': ' + self.CommaFormat(param[1].data[4]) + '<br/>',
        //     '最低價' + ': ' + self.CommaFormat(param[1].data[3]) + '<br/>',
        //     '收盤價' + ': ' + self.CommaFormat(param[1].data[2]) + '<br/>',
        //     '成交量' + ': ' + self.CommaFormat(param[0].data) + '<br/>',
        //   ].join('')
        // }
      },
    },
    dataZoom: [
      // {
      //   textStyle: {
      //     color: '#8392A5',
      //   },
      //   handleIcon:
      //     'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
      //   dataBackground: {
      //     areaStyle: {
      //       color: '#8392A5',
      //     },
      //     lineStyle: {
      //       opacity: 0.8,
      //       color: '#8392A5',
      //     },
      //   },
      //   brushSelect: true,
      // },
      {
        type: 'inside',
        startValue: 0,
        endValue: 30,
        // start: 50,
        // end: 100,
        zoomLock: true,
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        startValue: 0,
        endValue: 30,
        // start: 50,
        // end: 100,
        zoomLock: true,
        brushSelect: false,
      },
    ],
    xAxis: {
      data: [
        '2/1',
        '2/2',
        '2/3',
        '2/4',
        '2/5',
        '2/6',
        '2/7',
        '2/8',
        '2/9',
        '2/10',
        '2/11',
        '2/12',
        '2/13',
        '2/14',
        '2/15',
        '2/16',
        '2/17',
        '2/18',
        '2/19',
        '2/20',
        '2/21',
        '2/22',
        '2/23',
        '2/24',
        '2/25',
        '2/26',
        '2/27',
        '2/28',
        '3/1',
        '3/2',
        '3/3',
        '3/4',
        '3/5',
        '3/6',
        '3/7',
        '3/8',
        '3/9',
        '3/10',
        '3/11',
        '3/12',
        '3/13',
        '3/14',
        '3/15',
        '3/16',
        '3/17',
        '3/18',
        '3/19',
        '3/20',
        '3/21',
        '3/22',
        '3/23',
        '3/24',
        '3/25',
        '3/26',
        '3/27',
        '3/28',
        '3/29',
        '3/30',
        '3/31',
        '4/1',
        '4/2',
        '4/3',
        '4/4',
        '4/5',
        '4/6',
        '4/7',
        '4/8',
        '4/9',
        '4/10',
        '4/11',
        '4/12',
        '4/13',
        '4/14',
        '4/15',
        '4/16',
        '4/17',
        '4/18',
        '4/19',
        '4/20',
        '4/21',
        '4/22',
        '4/23',
        '4/24',
        '4/25',
        '4/26',
        '4/27',
        '4/28',
        '4/29',
        '4/30',
        '5/1',
      ],
    },
    yAxis: [
      {
        type: 'value',
        scale: true,
        name: 'mmHg',
        max: 210,
        min: 30,
        boundaryGap: [0.2, 0.2],
      },
      {
        type: 'value',
        scale: true,
        name: 'BPM',
        max: 210,
        min: 30,
        boundaryGap: [0.2, 0.2],
      },
    ],
    series: [
      {
        type: 'candlestick',
        data: [
          [88, 133, 143, 78],
          [],
          [],
          [],
          [82, 128, 138, 72],
          [81, 131, 145, 71],
          [],
          [],
          [80, 120, 130, 70],
          [137, 86, 147, 76],
          [],
          [133, 88, 143, 78],
          [129, 84, 139, 74],
          [131, 83, 141, 73],
          [140, 90, 150, 80],
          [],
          [],
          [],
          [127, 84, 137, 74],
          [122, 81, 132, 71],
          [144, 93, 154, 83],
          [138, 80, 148, 70],
          [129, 84, 139, 74],
          [133, 88, 143, 78],
          [],
          [],
          [142, 89, 152, 79],
          [125, 84, 135, 74],
          [128, 82, 138, 72],
          [131, 87, 141, 77],
          [133, 88, 143, 78],
          [161, 99, 171, 89],
          [159, 98, 169, 88],
          [],
          [],
          [144, 93, 154, 83],
          [138, 80, 148, 70],
          [168, 98, 178, 88],
          [169, 102, 179, 92],
          [172, 97, 182, 87],
          [],
          [],
          [159, 105, 169, 95],
          [177, 108, 187, 98],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [139, 88, 149, 78],
          [155, 95, 165, 85],
          [194, 125, 204, 115],
          [198, 126, 208, 126],
          [204, 120, 214, 110],
          [209, 127, 219, 117],
          [188, 133, 198, 123],
          [198, 136, 208, 126],
          [194, 129, 204, 119],
          [],
          [],
          [],
          [],
          [131, 87, 141, 77],
          [143, 85, 153, 75],
          [161, 99, 171, 89],
          [160, 100, 170, 90],
          [166, 100, 176, 90],
          [200, 111, 210, 101],
          [199, 110, 209, 100],
          [208, 126, 218, 116],
          [190, 131, 200, 121],
          [],
          [203, 129, 213, 119],
          [206, 125, 216, 115],
          [123, 88, 133, 78],
          [133, 83, 143, 73],
          [135, 87, 145, 77],
          [130, 84, 140, 74],
          [122, 82, 132, 72],
          [133, 85, 143, 75],
          [146, 90, 156, 80],
          [138, 90, 148, 80],
          [122, 82, 132, 72],
          [139, 87, 149, 77],
          [134, 88, 144, 78],
          [198, 136, 208, 126],
          [204, 120, 214, 110],
          [209, 127, 219, 117],
        ],
        itemStyle: {
          color: 'Red',
          color0: 'Gray',
          borderColor: undefined,
          borderColor0: undefined,
        },
        // itemStyle: {
        //   normal: {
        //     color: (item) => {
        //       return '#FFFF00'
        //     },
        //   },
        // },
      },
      {
        name: '脈搏',
        type: 'line',
        data: [
          88,
          '-',
          '-',
          '-',
          98,
          101,
          '-',
          '-',
          77,
          87,
          '-',
          99,
          88,
          69,
          69,
          '-',
          '-',
          '-',
          81,
          82,
          99,
          65,
          88,
          99,
          '-',
          '-',
          88,
          88,
          98,
          98,
          80,
          99,
          98,
          '-',
          '-',
          99,
          65,
          98,
          99,
          88,
          '-',
          '-',
          77,
          92,
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          '-',
          91,
          72,
          80,
          87,
          78,
          75,
          80,
          75,
          80,
          '-',
          '-',
          '-',
          '-',
          98,
          100,
          99,
          98,
          77,
          89,
          74,
          78,
          75,
          '-',
          74,
          79,
          88,
          87,
          100,
          99,
          98,
          101,
          100,
          85,
          77,
          88,
          98,
          87,
          78,
          75,
        ],
        connectNulls: true,
        itemStyle: {
          color: 'Pink',
        },
      },
    ],
  })
</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
