const subjects = {
  ok: true,
  subjects: [
    {
      fid: 1,
      name: '湯尼．史塔克',
      gender: 'M',
      birthday: '1965-04-04',
      height: 182,
      heightUnit: 'cm',
      id: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'IronMan',
        },
      ],
      created: '2021-06-22T03:14:59.099Z',
      updated: '2021-06-22T03:14:59.099Z',
      group: {
        id: 13,
        title: '鋼鐵人組',
        created: '2015-05-01T00:00:00.000Z',
        updated: '2019-04-22T00:00:00.000Z',
      },
    },
    {
      fid: 2,
      name: '娜塔莎·羅曼諾夫',
      gender: 'F',
      birthday: '1984-11-22',
      height: 175,
      heightUnit: 'cm',
      id: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'BlackWidow',
        },
      ],
      created: '2021-06-22T03:14:59.330Z',
      updated: '2021-06-22T03:14:59.330Z',
      group: {
        id: 13,
        title: '鋼鐵人組',
        created: '2015-05-01T00:00:00.000Z',
        updated: '2019-04-22T00:00:00.000Z',
      },
    },
    {
      fid: 3,
      name: '史蒂芬·羅傑斯',
      gender: 'M',
      birthday: '1981-06-13',
      height: 188,
      heightUnit: 'cm',
      id: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'CaptainAmerica',
        },
      ],
      created: '2021-06-22T03:15:34.495Z',
      updated: '2021-06-22T03:15:34.495Z',
      group: {
        id: 14,
        title: '美國隊長組',
        created: '2015-05-01T00:00:00.000Z',
        updated: '2019-04-22T00:00:00.000Z',
      },
    },
    {
      fid: 4,
      name: '巴奇·巴恩斯',
      gender: 'M',
      birthday: '1982-08-13',
      height: 187,
      heightUnit: 'cm',
      id: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'WinterSoldier',
        },
      ],
      created: '2021-06-22T03:15:34.730Z',
      updated: '2021-06-22T03:15:34.730Z',
      group: {
        id: 14,
        title: '美國隊長組',
        created: '2015-05-01T00:00:00.000Z',
        updated: '2019-04-22T00:00:00.000Z',
      },
    },
    {
      fid: 49,
      name: '王大明',
      id: [
        {
          idType: 'YC_Member',
          idValue: '0101383551733',
        },
      ],
      created: '2021-07-14T03:34:29.701Z',
      updated: '2021-07-14T03:34:29.000Z',
    },
  ],
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

module.exports = [
  {
    url: '/subjects',
    type: 'get',
    response() {
      return subjects
    },
  },
  {
    url: '/subjects',
    type: 'patch',
    response() {
      return {
        ok: true,
      }
    },
  },
  {
    url: '/subjects',
    type: 'post',
    response(config) {
      config.body.subjects.forEach((item) => {
        item.fid = getRandomInt(10000000)
        item.created = '2021-07-22T03:14:59.099Z'
        item.updated = '2021-07-22T03:14:59.099Z'
        subjects.subjects.push(item)
      })
      return {
        ok: true,
      }
    },
  },
]
