const groups = {
  ok: true,
  groups: [
    {
      title: '鋼鐵人組',
      groupId: 13,
    },
    {
      title: '美國隊長組',
      groupId: 14,
    },
  ],
}

const coutAndGroup = {
  ok: true,
  groups: [
    {
      created: '2021-05-28T08:32:48.571Z',
      title: '遠距之愛測試群組1',
      groupId: 1,
      subjectCount: 1,
    },
    {
      created: '2021-06-17T11:02:32.893Z',
      title: '遠距之愛測試群組2',
      groupId: 2,
      subjectCount: 0,
    },
    {
      created: '2021-06-17T14:10:48.387Z',
      title: '遠距之愛測試群組3',
      groupId: 3,
      subjectCount: 0,
    },
  ],
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

module.exports = [
  {
    url: '/groups.count',
    type: 'get',
    response() {
      return coutAndGroup
    },
  },
  {
    url: '/groups',
    type: 'get',
    response() {
      return groups
    },
  },
  {
    url: '/groups',
    type: 'delete',
    response() {
      coutAndGroup.groups.pop()
      return {
        ok: true,
      }
    },
  },
  {
    url: '/groups',
    type: 'patch',
    response(config) {
      if (coutAndGroup.groups.length > 0) {
        coutAndGroup.groups[0].title = config.body.title
      }
      return {
        ok: true,
      }
    },
  },
  {
    url: '/groups',
    type: 'post',
    response(config) {
      const item = {
        created: '2021-09-28T08:32:48.571Z',
        title: config.body.title,
        groupId: getRandomInt(100000),
        subjectCount: getRandomInt(100),
      }
      coutAndGroup.groups.push(item)
      return {
        ok: true,
      }
    },
  },
]
