import { render } from "./WeightThreshold.vue?vue&type=template&id=66b2ae97&scoped=true"
import script from "./WeightThreshold.vue?vue&type=script&lang=js"
export * from "./WeightThreshold.vue?vue&type=script&lang=js"

import "./WeightThreshold.vue?vue&type=style&index=0&id=66b2ae97&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-66b2ae97"
/* hot reload */
if (module.hot) {
  script.__hmrId = "66b2ae97"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('66b2ae97', script)) {
    api.reload('66b2ae97', script)
  }
  
  module.hot.accept("./WeightThreshold.vue?vue&type=template&id=66b2ae97&scoped=true", () => {
    api.rerender('66b2ae97', render)
  })

}

script.__file = "src/views/meaureThreshold/components/WeightThreshold.vue"

export default script