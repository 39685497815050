<template>
  <div class="test-container">
    <a-alert message="動態 route 測試"></a-alert>
  </div>
</template>

<script>
  export default {
    name: 'Test',
  }
</script>
