const measurementAllData = {
  ok: true,
  data: [
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456789',
        },
      ],
      fid: 1,
      name: '郝健康',
      groupID: 1,
      times: 3,
      bloodPressureAbnormalLevel: 1,
      glucoseAbnormalLevel: 2,
      spo2AbnormalLevel: 1,
      bodyTemperatureAbnormalLevel: 2,
      bmiAbnormalLevel: 0,
      detail: {
        bloodPressure: {
          times: 3,
          description: '第一級高血壓',
          diastolic: 140,
          systolic: 90,
          pp: 50,
          pulse: 50,
          arr: '2/3',
          pc: '1/3',
          afib: '',
        },
        glucose: {
          times: 3,
          description: '糖尿病',
          fasting: 150,
          beforeMeal: 250,
        },
        spo2: {
          times: 2,
          description: '血氧濃度過低',
          value: 89,
          act: 2,
        },
        bodyTemperature: {
          times: 2,
          description: '溫度過高',
          value: '38.2',
        },
        bodyWeight: {
          times: 1,
          description: '輕度肥胖,內臟脂肪過高',
          value: 78.9,
          bmi: 28.4,
          bodyFat: 35.4,
          muscleMass: 35.4,
          visceralFat: 15,
          bmr: 1000,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456780',
        },
      ],
      fid: 2,
      name: '真健康',
      groupID: 1,
      times: 5,
      bloodPressureAbnormalLevel: 2,
      glucoseAbnormalLevel: 0,
      spo2AbnormalLevel: 2,
      bodyTemperatureAbnormalLevel: 1,
      bmiAbnormalLevel: 1,
      detail: {
        bloodPressure: {
          times: 6,
          description: '第二級高血壓',
          diastolic: 143,
          systolic: 99,
          pp: 55,
          pulse: 52,
          arr: '1/3',
          pc: '2/3',
          afib: '',
        },
        glucose: {
          times: 4,
          description: '糖尿病',
          fasting: 160,
          beforeMeal: 220,
        },
        spo2: {
          times: 3,
          description: '血氧濃度過低',
          value: 91,
          act: 3,
        },
        bodyTemperature: {
          times: 3,
          description: '',
          value: '37.2',
        },
        bodyWeight: {
          times: 1,
          description: '小胖胖,內臟脂肪偏高',
          value: 77.9,
          bmi: 25.4,
          bodyFat: 32.4,
          muscleMass: 31.4,
          visceralFat: 13,
          bmr: 999,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456789',
        },
      ],
      fid: 1,
      name: '郝健康',
      groupID: 1,
      times: 3,
      bloodPressureAbnormalLevel: 1,
      glucoseAbnormalLevel: 2,
      spo2AbnormalLevel: 1,
      bodyTemperatureAbnormalLevel: 2,
      bmiAbnormalLevel: 0,
      detail: {
        bloodPressure: {
          times: 3,
          description: '第一級高血壓',
          diastolic: 140,
          systolic: 90,
          pp: 50,
          pulse: 50,
          arr: '2/3',
          pc: '1/3',
          afib: '',
        },
        glucose: {
          times: 3,
          description: '糖尿病',
          fasting: 150,
          beforeMeal: 250,
        },
        spo2: {
          times: 2,
          description: '血氧濃度過低',
          value: 89,
          act: 2,
        },
        bodyTemperature: {
          times: 2,
          description: '溫度過高',
          value: '38.2',
        },
        bodyWeight: {
          times: 1,
          description: '輕度肥胖,內臟脂肪過高',
          value: 78.9,
          bmi: 28.4,
          bodyFat: 35.4,
          muscleMass: 35.4,
          visceralFat: 15,
          bmr: 1000,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456780',
        },
      ],
      fid: 2,
      name: '真健康',
      groupID: 1,
      times: 5,
      bloodPressureAbnormalLevel: 2,
      glucoseAbnormalLevel: 0,
      spo2AbnormalLevel: 2,
      bodyTemperatureAbnormalLevel: 1,
      bmiAbnormalLevel: 1,
      detail: {
        bloodPressure: {
          times: 6,
          description: '第二級高血壓',
          diastolic: 143,
          systolic: 99,
          pp: 55,
          pulse: 52,
          arr: '1/3',
          pc: '2/3',
          afib: '',
        },
        glucose: {
          times: 4,
          description: '糖尿病',
          fasting: 160,
          beforeMeal: 220,
        },
        spo2: {
          times: 3,
          description: '血氧濃度過低',
          value: 91,
          act: 3,
        },
        bodyTemperature: {
          times: 3,
          description: '',
          value: '37.2',
        },
        bodyWeight: {
          times: 1,
          description: '小胖胖,內臟脂肪偏高',
          value: 77.9,
          bmi: 25.4,
          bodyFat: 32.4,
          muscleMass: 31.4,
          visceralFat: 13,
          bmr: 999,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456789',
        },
      ],
      fid: 1,
      name: '郝健康',
      groupID: 1,
      times: 3,
      bloodPressureAbnormalLevel: 1,
      glucoseAbnormalLevel: 2,
      spo2AbnormalLevel: 1,
      bodyTemperatureAbnormalLevel: 2,
      bmiAbnormalLevel: 0,
      detail: {
        bloodPressure: {
          times: 3,
          description: '第一級高血壓',
          diastolic: 140,
          systolic: 90,
          pp: 50,
          pulse: 50,
          arr: '2/3',
          pc: '1/3',
          afib: '',
        },
        glucose: {
          times: 3,
          description: '糖尿病',
          fasting: 150,
          beforeMeal: 250,
        },
        spo2: {
          times: 2,
          description: '血氧濃度過低',
          value: 89,
          act: 2,
        },
        bodyTemperature: {
          times: 2,
          description: '溫度過高',
          value: '38.2',
        },
        bodyWeight: {
          times: 1,
          description: '輕度肥胖,內臟脂肪過高',
          value: 78.9,
          bmi: 28.4,
          bodyFat: 35.4,
          muscleMass: 35.4,
          visceralFat: 15,
          bmr: 1000,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456780',
        },
      ],
      fid: 2,
      name: '真健康',
      groupID: 1,
      times: 5,
      bloodPressureAbnormalLevel: 2,
      glucoseAbnormalLevel: 0,
      spo2AbnormalLevel: 2,
      bodyTemperatureAbnormalLevel: 1,
      bmiAbnormalLevel: 1,
      detail: {
        bloodPressure: {
          times: 6,
          description: '第二級高血壓',
          diastolic: 143,
          systolic: 99,
          pp: 55,
          pulse: 52,
          arr: '1/3',
          pc: '2/3',
          afib: '',
        },
        glucose: {
          times: 4,
          description: '糖尿病',
          fasting: 160,
          beforeMeal: 220,
        },
        spo2: {
          times: 3,
          description: '血氧濃度過低',
          value: 91,
          act: 3,
        },
        bodyTemperature: {
          times: 3,
          description: '',
          value: '37.2',
        },
        bodyWeight: {
          times: 1,
          description: '小胖胖,內臟脂肪偏高',
          value: 77.9,
          bmi: 25.4,
          bodyFat: 32.4,
          muscleMass: 31.4,
          visceralFat: 13,
          bmr: 999,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456789',
        },
      ],
      fid: 1,
      name: '郝健康',
      groupID: 1,
      times: 3,
      bloodPressureAbnormalLevel: 1,
      glucoseAbnormalLevel: 2,
      spo2AbnormalLevel: 1,
      bodyTemperatureAbnormalLevel: 2,
      bmiAbnormalLevel: 0,
      detail: {
        bloodPressure: {
          times: 3,
          description: '第一級高血壓',
          diastolic: 140,
          systolic: 90,
          pp: 50,
          pulse: 50,
          arr: '2/3',
          pc: '1/3',
          afib: '',
        },
        glucose: {
          times: 3,
          description: '糖尿病',
          fasting: 150,
          beforeMeal: 250,
        },
        spo2: {
          times: 2,
          description: '血氧濃度過低',
          value: 89,
          act: 2,
        },
        bodyTemperature: {
          times: 2,
          description: '溫度過高',
          value: '38.2',
        },
        bodyWeight: {
          times: 1,
          description: '輕度肥胖,內臟脂肪過高',
          value: 78.9,
          bmi: 28.4,
          bodyFat: 35.4,
          muscleMass: 35.4,
          visceralFat: 15,
          bmr: 1000,
        },
      },
    },
    {
      ids: [
        {
          idType: 'CUSTOM_ID',
          idValue: 'A123456780',
        },
      ],
      fid: 2,
      name: '真健康',
      groupID: 1,
      times: 5,
      bloodPressureAbnormalLevel: 2,
      glucoseAbnormalLevel: 0,
      spo2AbnormalLevel: 2,
      bodyTemperatureAbnormalLevel: 1,
      bmiAbnormalLevel: 1,
      detail: {
        bloodPressure: {
          times: 6,
          description: '第二級高血壓',
          diastolic: 143,
          systolic: 99,
          pp: 55,
          pulse: 52,
          arr: '1/3',
          pc: '2/3',
          afib: '',
        },
        glucose: {
          times: 4,
          description: '糖尿病',
          fasting: 160,
          beforeMeal: 220,
        },
        spo2: {
          times: 3,
          description: '血氧濃度過低',
          value: 91,
          act: 3,
        },
        bodyTemperature: {
          times: 3,
          description: '',
          value: '37.2',
        },
        bodyWeight: {
          times: 1,
          description: '小胖胖,內臟脂肪偏高',
          value: 77.9,
          bmi: 25.4,
          bodyFat: 32.4,
          muscleMass: 31.4,
          visceralFat: 13,
          bmr: 999,
        },
      },
    },
  ],
}

const measurementDetailData = {
  ok: true,
  amount: {
    bloodPressure: 60,
    glucose: 15,
    spo2: 55,
    bodyTemperature: 12,
    bodyWeight: 34,
  },
  abnormalPercentage: {
    bloodPressure: 77,
    glucose: 20,
    spo2: 9,
    bodyTemperature: 0,
    bodyWeight: 28,
  },
  data: [
    {
      recordTime: 1616169600000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 100,
      arr: false,
      pc: false,
      afrib: false,
      fasting: 131,
      spo2: {
        low: 100,
        high: 100,
      },
      act: 1,
      bodyTemperature: 38.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616256000000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: true,
      pc: false,
      afrib: true,
      beforeMeal: 110,
      spo2: {
        low: 99,
        high: 100,
      },
      act: 2,
    },
    {
      recordTime: 1616428800000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: false,
      pc: false,
      afrib: false,
      afterMeal: 131,
      spo2: {
        low: 98,
        high: 99,
      },
      act: 1,
      bodyTemperature: 37.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616169600000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 100,
      arr: false,
      pc: false,
      afrib: false,
      fasting: 131,
      spo2: {
        low: 100,
        high: 100,
      },
      act: 1,
      bodyTemperature: 38.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616256000000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: true,
      pc: false,
      afrib: true,
      beforeMeal: 110,
      spo2: {
        low: 99,
        high: 100,
      },
      act: 2,
    },
    {
      recordTime: 1616428800000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: false,
      pc: false,
      afrib: false,
      afterMeal: 131,
      spo2: {
        low: 98,
        high: 99,
      },
      act: 1,
      bodyTemperature: 37.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616169600000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 100,
      arr: false,
      pc: false,
      afrib: false,
      fasting: 131,
      spo2: {
        low: 100,
        high: 100,
      },
      act: 1,
      bodyTemperature: 38.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616256000000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: true,
      pc: false,
      afrib: true,
      beforeMeal: 110,
      spo2: {
        low: 99,
        high: 100,
      },
      act: 2,
    },
    {
      recordTime: 1616428800000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: false,
      pc: false,
      afrib: false,
      afterMeal: 131,
      spo2: {
        low: 98,
        high: 99,
      },
      act: 1,
      bodyTemperature: 37.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616169600000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 100,
      arr: false,
      pc: false,
      afrib: false,
      fasting: 131,
      spo2: {
        low: 100,
        high: 100,
      },
      act: 1,
      bodyTemperature: 38.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
    {
      recordTime: 1616256000000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: true,
      pc: false,
      afrib: true,
      beforeMeal: 110,
      spo2: {
        low: 99,
        high: 100,
      },
      act: 2,
    },
    {
      recordTime: 1616428800000,
      diastolic: 140,
      systolic: 89,
      pp: 51,
      pulse: 60,
      arr: false,
      pc: false,
      afrib: false,
      afterMeal: 131,
      spo2: {
        low: 98,
        high: 99,
      },
      act: 1,
      bodyTemperature: 37.2,
      bodyWeight: 38.2,
      bmi: 24.3,
      bodyFat: 23.4,
      muscleMass: 34.5,
      visceralFat: 7,
      bmr: 1234,
    },
  ],
}

module.exports = [
  {
    url: '/measurements/query/groupBySubjectsAVG',
    type: 'post',
    response() {
      return measurementAllData
    },
  },
  {
    url: '/measurements/web/query/detailFromAVG',
    type: 'post',
    response() {
      return measurementDetailData
    },
  },
]
