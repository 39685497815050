import { render } from "./BodyWeightChart.vue?vue&type=template&id=078be4d4&scoped=true"
import script from "./BodyWeightChart.vue?vue&type=script&setup=true&lang=js"
export * from "./BodyWeightChart.vue?vue&type=script&setup=true&lang=js"

import "./BodyWeightChart.vue?vue&type=style&index=0&id=078be4d4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-078be4d4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "078be4d4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('078be4d4', script)) {
    api.reload('078be4d4', script)
  }
  
  module.hot.accept("./BodyWeightChart.vue?vue&type=template&id=078be4d4&scoped=true", () => {
    api.rerender('078be4d4', render)
  })

}

script.__file = "src/views/measurements/casePeriod/BodyWeightChart.vue"

export default script