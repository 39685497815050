import { render } from "./index.vue?vue&type=template&id=f263383c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f263383c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f263383c', script)) {
    api.reload('f263383c', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=f263383c", () => {
    api.rerender('f263383c', render)
  })

}

script.__file = "src/views/underConstruction/index.vue"

export default script